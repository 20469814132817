/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { I18n } from "i18n-js"
import CoursesForm from './components/courses_form'
import ClubPostForm from './components/club_post_form'
import VideoPlayer from './components/video_player'
import MceEditor from './components/mce_editor'
import Zoom from './components/zoom'

// Locales
window.I18n = new I18n()
window.I18n.defaultLocale = 'cs'
window.I18n.enableFallback = true
window.I18n.locale = document.documentElement.lang
window.translations ||= {}
window.I18n.store(window.translations)


// Fetch translations
const fetchTranslations = async ({ locale } = { locale: window.I18n.locale}) => {
  const response = await fetch(window.helperData.translation_paths[locale])
  const data = await response.json()
  Object.assign(window.translations, data)
  window.I18n.store(window.translations)
}

window.addTranslations = (locale) => {
  fetchTranslations({locale})
}

// Fetch English for fallback
if (window.I18n.locale != 'cs') {
  fetchTranslations({locale: 'cs'})
}

window.I18n.pluralization.register('cs', (_i18n, count) => {
  if (count == 1) return ["one"]
  if (count < 5) return ["few"]
  return ["other"]
})

window.mountedComponents = []
window.ReactDOM = ReactDOM

let precompile = false

const getUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  })
}

const renderComponent = ({
  element,
  component,
  props
}) => {
  if (!element || element.dataset.reactComponentRendered) return

  let uuid = getUUID()

  ReactDOM.render(
    React.createElement(
      component,
      (props || JSON.parse(element.dataset.props))
    ),
    element
  )

  element.dataset.reactComponentRendered = true
  window.mountedComponents.push({
    uuid: uuid,
    element: element
  })

}

const unmountComponents = () => {
  window.mountedComponents.forEach(component => {
    try {
      ReactDOM.unmountComponentAtNode(component.element)
    }
    catch(error) {
      // console.log(error)
    }
  })
}

document.addEventListener('DOMContentLoaded', () => {

  renderComponent({
    element: document.getElementById('react-courses-form'),
    component: CoursesForm
  })

  renderComponent({
    element: document.getElementById('react-club-post-form'),
    component: ClubPostForm
  })

  renderComponent({
    element: document.getElementById('video-player'),
    component: VideoPlayer
  })

  renderComponent({
    element: document.getElementById('zoom'),
    component: Zoom
  })

  document.querySelectorAll('.mce-editor').forEach((element) => {
    renderComponent({
      element,
      component: MceEditor
    })
  })
})

window.addEventListener('unload', unmountComponents)
