import { useEffect, useState } from 'react'
import { ZoomMtg } from "@zoom/meetingsdk"

const Zoom = () => {

  ZoomMtg.preLoadWasm()
  ZoomMtg.prepareWebSDK()

  const [zoomData, setZoomData] = useState()
  const [meetingStarted, setMeetingStarted] = useState(false)

  useEffect(() => {
    if (zoomData) {
      ZoomMtg.init({
        leaveUrl: '/',
        patchJsMedia: true,
        leaveOnPageUnload: true,
        success: (success) => {
          setMeetingStarted(true)
          ZoomMtg.join({
            signature: zoomData.signature,
            meetingNumber: zoomData.meeting_number,
            userName: "Richard",
            sdkKey: zoomData.client_id,
            passWord: zoomData.password,
            success: (success) => {
              // setMeetingStarted(true)
              console.log("joined", success)
            },
            error: (error) => {
              console.log(error)
            }
          })
        },
        error: (error) => {
          console.log(error)
        }
      })
    }
  }, [zoomData])

  const createMeeting = () => {
    $.ajax({
      method: 'POST',
      url: '/trainer/trainings/create_meeting',
      data: {
        role: 1
      },
      success: (response) => {
        setZoomData(response)
      }
    })
  }

  return <>
    {!meetingStarted ?
      <a href="#" className="btn btn-themecolor" style={{position: 'absolute', zIndex: '99999', backgroundColor: 'red'}} onClick={(e) => {e.preventDefault(); createMeeting()}}>Start</a> : null 
    }
  </>
}

export default Zoom